import React from "react";
import logo from "../../assets/images/logo.svg";

function commingSoon() {
	return (
		<main>
			<div>
				<img src={logo} alt="turf-tally" />
				<p>Your off field partner</p>
			</div>
		</main>
	);
}

export default commingSoon;
