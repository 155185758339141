import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import CommingSoon from "./components/commingSoon/commingSoon";
import Header from "./components/header/header";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>		
		<CommingSoon />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
